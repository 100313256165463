.body {
  /* background-image: linear-gradient(to bottom right, #1CCBC7, #3E4687, #3E4687); */
  /* background: linear-gradient(120.03deg, #1CCBC7 0.86%, #3E4687 60.97%); */
  background: #fff;
  background-size: cover;
  height: 100%;
  width: 100%;
  font-family: "galano_grotesqueregular" !important;
}
.body .unsub {
  background: white;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
.body .unsub .middle_left,
.body .unsub .bottom_right,
.body .unsub .top_right,
.body .unsub .circle_top_right {
  z-index: 1000;
}
.m_t {
  margin-top: 40px;
}
.m_t2 {
  margin-top: 130px;
}
.radio {
  line-height: 2;
}
.radio label {
  color: #0C5088;
}
.unsub_content h1 {
  color: #0C5088;
  font-weight: 700;
}
.unsub_content p {
  color: #0C5088;
  padding: 0 400px;
}
.btn_unsub {
  width: 100%;
  background: #0A58CA;
  color: white;
  font-weight: 600;
  margin-top: 100px;
}
.btn_unsub:hover {
  color: #fff;
}
.list_content {
  z-index: 10000;
  margin-top: 100px;
}
/* .language_btn_main {
  top: 35px;
  right: 80px;
} */

.arrow_img img {
  width: auto;
  height: 200px;
}
.arrow_img h4 {
  display: flex;
  justify-content: center;
}
.dropdown {
  top: 35px;
  right: 80px;
  margin: 0 auto;
  width: 220px;
  border: 1px solid #0A58CA;
  background: transparent;
  border-radius: 8px;
}
.dropdown.active {
  background-color: #fff;
  color: #0A58CA;
}
.dropdown.active .dropdown__text, .dropdown.active .dropdown__text.color_white {
  color: #3D4586!important;
}
.dropdown__text {
  color: #3D4586;
  padding: 10px 16px;
  cursor: pointer;
  height: auto;
}
.dropdown__text:hover {
  color: #3D4586;
  background: transparent;
}

.minwidth400px.color_white.theme_bg .dropdown__text:after {
  content: "";
  transition: all 0.3s;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  float: right;
  margin-top: 5px;
  margin-right: 6px;
  padding: 5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.dropdown__text:after {
  content: "";
  transition: all 0.3s;
  border: solid #3D4586;
  border-width: 0 2px 2px 0;
  float: right;
  margin-top: 5px;
  margin-right: 6px;
  padding: 5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.dropdown.active .dropdown__text:after {
  margin-top: 9px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.dropdown__items {
  position: relative;
  visibility: hidden;
  opacity: 0;
  max-height: 0px;
  transition: max-height 0.6s ease;
}
.dropdown.active .dropdown__items {
  visibility: visible;
  border-top: 1px solid #F1F1F1;
  height: auto;
  max-height: 290px;
  opacity: 1;
  transition: max-height 0.7s, opacity 0.6s, visibility 0.7s ease;
}
.dropdown__item {
  cursor: pointer;
  padding: 10px 18px;
}
.dropdown__item:not(:last-child) {
  border-bottom: 1px solid #F1F1F1;
}
.dropdown__item:hover {
  color: #0A58CA;
  background: #fff;
}
.dropdown__item_address {
  color: #414882;
  background: #fff;
  border-bottom: 1px solid #414882;
  padding: 10px 18px;
}
.dropdown__item_address:hover {
  cursor: pointer;
  background: #414882;
  color: #fff;
}
.pencil {
  color: #414882;
  border: 1px solid #414882;
  border-radius: 100%;
}
.dropdown__item_address:hover .pencil {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 100%;
}
.dropdown-el {
  position: relative;
  display: inline-block;
  min-height: 2.7em;
  max-height: 2.7em;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  color: #fff;
  outline: none;
  border: 1px solid #fff;
  border-radius: 8px;
  background-color: transparent;
  transition: 0.3s all ease-in-out;
}
.dropdown-el input:focus + label {
  background: #def;
}
.dropdown-el input {
  width: 1px;
  height: 1px;
  display: inline-block;
  position: absolute;
  opacity: 0.01;
}
.dropdown-el label {
  border-top: 0.06em solid #D9D9D9;
  display: block;
  height: 2.7em;
  min-width: 150px;
  line-height: 2em;
  padding: 3px 25px;
  cursor: pointer;
  position: relative;
  /* transition: 0.3s color ease-in-out; */
}
.dropdown-el label:nth-child(2) {
  margin-top: 2.7em;
  border-top: 0.06em solid #D9D9D9;
}
.dropdown-el input:checked + label {
  display: block;
  border-top: none;
  position: absolute;
  top: 0;
  width: 100%;
}
.dropdown-el input:checked + label:nth-child(2) {
  margin-top: 0;
  position: relative;
}
.dropdown-el.expanded {
  border: 0.06em solid #fff;
  background: #fff;
  color: #0A58CA;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 3px 3px 5px 0px;
  max-height: 15.5em;
}
.dropdown-el.expanded label {
  border-top: 0.06em solid #F1F1F1;
}
.dropdown-el.expanded label:hover {
  color: #0A58CA;
}
.dropdown-el.expanded input:checked + label {
  color: #0A58CA;
}
/* responsive */
@media only screen and (max-width: 480px) {
  .m_t {
    margin: 0;
  }
  .unsub_content p {
    padding: 0 30px !important;
  }
  .unsub_content h1 {
    font-size: 22px;
  }
  .list_content {
    margin: 20px 50px 0;
    z-index: 1000;
  }
  .body .unsub .middle_left {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .m_t .unsub_content p {
    padding: 0 50px;
  }
  .wosh_img {
    text-align: center;
  }
  .wosh_img img {
    width: auto;
    height: 50px;
  }
  .arrow_img {
    text-align: left;
  }
  .arrow_img img {
    width: auto;
    height: 50px;
  }
  .arrow_img h4 {
    font-size: 26px;
  }
  .body .unsub .middle_left{
    display: none;
  }
}
@media only screen and (max-width: 1040px) {
  .unsub_content p {
    padding: 0 150px;
  }
  .list_content {
    margin-top: 20px;
  }
  .btn_unsub {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 1240px) {
  .unsub_content p {
    padding: 0 200px;
  }
  .list_content {
    margin-top: 20px;
  }
  .btn_unsub {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 1380px) {
  .unsub_content p {
    padding: 0 300px;
  }
  .list_content {
    margin-top: 20px;
  }
  .btn_unsub {
    margin-top: 20px;
  }
}