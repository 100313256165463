/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 25, 2021 */

@font-face {
  font-family: "galano_grotesquebold";
  src: url("./fonts/galanogrotesquebold.woff2") format("woff2"),
    url("./fonts/galanogrotesquebold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "galano_grotesqueregular";
  src: url("./fonts/galanogrotesqueregular.woff2") format("woff2"),
    url("./fonts/galanogrotesqueregular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "galano_grotesquebold";
  src: url("./fonts/galanogrotesquebold.woff2") format("woff2"),
    url("./fonts/galanogrotesquebold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "galano_grotesqueitalic";
  src: url("./fonts/galanogrotesqueitalic.woff2") format("woff2"),
    url("./fonts/galanogrotesqueitalic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "galano_grotesque_altmedium";
  src: url("./fonts/galanogrotesquealtmedium-webfont.woff2") format("woff2"),
    url("./fonts/galanogrotesquealtmedium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "galano_grotesquesemibold";
  src: url("./fonts/galanogrotesquesemibold-webfont.woff2") format("woff2"),
    url("./fonts/galanogrotesquesemibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "galano_grotesque_altregular";
  src: url("./fonts/galanogrotesquealtregular-webfont.woff2") format("woff2"),
    url("./fonts/galanogrotesquealtregular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html {
  min-height: 100%;
}

.vh-90 {
  height: 90vh !important;
}

.w-40 {
  width: 40%;
}

.box_404 {
  background: #ffffff5e;
}

.z_index_one {
  z-index: 1;
}

.h_30px {
  max-height: 25px;
  max-width: 30px;
}

.sr_font {
  font-size: 1vw;
}

.top_right {
  position: fixed;
  top: 0;
  z-index: 1000;
  right: 0;
}

.rectangle_top_left {
  position: fixed;
  top: 22px;
  left: 110px;
  z-index: -1;
}

.rectangle_top_left img {
  width: 200px;
  height: 100px;
}

@media only screen and (max-width: 991px) {
  .rectangle_top_left img {
    display: none;
  }
}

.tabContentLeftvector {
  margin-top: -50px;
}

.tabContentRightvector {
  margin-top: 10px;
}

.circle_top_right {
  position: fixed;
  top: 130px;
  right: 420px;
  z-index: -1;
}

.circle_top_right img {
  width: 70px;
  height: 70px;
}

.circle_top_right_dot {
  position: fixed;
  top: 120px;
  right: 510px;
  z-index: -1;
}

.circle_top_right_dot img {
  width: 40px;
  height: 40px;
}

.circle_left_middle_dot {
  position: fixed;
  top: 415px;
  left: 80px;
  z-index: -1;
}

.circle_left_middle_dot img {
  width: 60px;
  height: 60px;
}

.circle_right_middle_dot {
  position: fixed;
  top: 580px;
  right: 450px;
  z-index: -1;
}

.circle_right_middle_dot img {
  width: 60px;
  height: 60px;
}

.nav-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.bottom_right {
  width: 400px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.owl-carousel#clender_slider .owl-stage-outer {
  margin-left: 0 !important;
  /* width: 2%!important; */
}

.clender_date #clender_slider li {
  width: auto !important;
}

.owl-carousel#clender_slider .owl-stage-outer {
  margin-left: 35px;
}

.owl-carousel.owl-theme#clender_slider .owl-nav .owl-next {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  outline: none;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all linear 0.2s;
  background: #e7e9ff !important;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 19px rgba(255, 255, 255, 0.26);
  color: #3d4586;
  margin-left: 10px;
}

.owl-theme#clender_slider .owl-nav button span {
  height: 42px;
}

.owl-carousel.owl-theme#clender_slider .owl-nav .owl-prev {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  outline: none;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all linear 0.2s;
  background: #e7e9ff !important;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 19px rgba(255, 255, 255, 0.26);
  color: #3d4586;
  margin-right: 10px;
}

.bounce {
  color: #3d4585;
  position: absolute;
  left: 50%;
  top: 40%;
  width: 80px;
  height: 80px;
}

.owl-theme#clender_slider .owl-nav {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px !important;
}

.middle_left {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.middle_left_white {
  position: fixed;
  top: 360px;
  left: 90px;
  z-index: 1;
}

.middle_left_white img {
  width: 100px;
  height: 100px;
}

.middle_left_top_content_white {
  position: fixed;
  top: 165px;
  left: 420px;
  z-index: -1;
}

.middle_left_top_content_white img {
  width: 100px;
  height: 100px;
}

.rating-star-40px {
  font-size: 40px;
}

.screen_center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.text_hidden {
  overflow-x: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 92%;
  max-height: 36px;
}

.dropdown__text::before {
  background-color: white;
  width: 20px;
  height: 20px;
}

.dropdown__text:after {
  margin-top: -20px !important;
}

body {
  font-family: "galano_grotesqueregular" !important;
}

.body {
  /* background-image: linear-gradient(to bottom right, #1ccbc7, #3e4687, #3e4687); */
  /* background: linear-gradient(120.03deg, #1ccbc7 0.86%, #3e4687 60.97%); */
  background: #fff;
  background-size: 100% !important;
  background-attachment: fixed !important;
  background-repeat: no-repeat !important;
  height: 100%;
  width: 100%;
  font-family: "galano_grotesqueregular" !important;
}

.btn_modal:hover {
  color: #7480e0;
}

#loginAutofill > input:-webkit-autofill {
  -webkit-text-fill-color: #3d4586 !important;
}

#loginAutofill input:-webkit-autofill,
#loginAutofill input:-webkit-autofill:hover,
#loginAutofill input:-webkit-autofill:focus,
#loginAutofill input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #3d4586 !important;
}

#exampleInputPassword1::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #3d4586 !important;
  opacity: 1;
  /* Firefox */
}

#exampleInputPassword1:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #3d4586;
}

#exampleInputPassword1::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #3d4586;
}

#darkautofill > input:-webkit-autofill {
  -webkit-text-fill-color: #000 !important;
}

#darkautofill input:-webkit-autofill,
#darkautofill input:-webkit-autofill:hover,
#darkautofill input:-webkit-autofill:focus,
#darkautofill input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000 !important;
}

.w_min_content {
  width: max-content;
}

#stripe_form input:-webkit-autofill,
#stripe_form input:-webkit-autofill:hover,
#stripe_form input:-webkit-autofill:focus,
#stripe_form input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background: transparent;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #3d4586 !important;
}

/*  progress bar  */

/* .main_outer_shadow {
  width: 30px;
  height: 30px;
  background: rgba(138, 227, 232, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
} */

#stripe_form .Form {
  padding: 30px !important;
}

#stripe_form .FormRowLabel {
  font-family: "galano_grotesque_altmedium";
  margin-bottom: 0;
}

.StripeFormInput::placeholder {
  color: #00000080 !important;
}

.calender-div {
  display: flex;
  /* margin-top: 10px; */
  /* margin-left: 160px; */
}

/* .outer_shadow {
  width: 23px;
  height: 23px;
  background: rgba(138, 227, 232, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
} */

.kilo_tab_shadow {
  box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
  border-radius: 10px !important;
}

.current_step {
  width: 17px !important;
  height: 17px !important;
  background: #8ae3e8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-calender {
  background: #3d4587;
  color: #fff;
  border: none;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* new tracking */

.order_tracking_message {
  background: #ffffff;
  width: 100%;
  max-width: 800px;
  border: 5px solid rgba(225, 192, 255, 1);
  border-radius: 12px;
  padding: 40px 156px;
}

.order_tracking_items {
  width: 100%;
  max-width: 800px;
  background: #ffffff;
  width: 100%;
  border: 5px solid rgba(61, 69, 134, 1);
  border-radius: 12px;
}

.wosh_wallet_tracking {
  width: 100%;
  max-width: 800px;
  background: #ffffff;
  width: 100%;
  border: 5px solid rgba(61, 69, 134, 1);
  border-radius: 12px;
}

/* view order details */

.view-order-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.view-order-modal-content {
  background: white;
  border-radius: 20px;
  width: 100%;
  max-width: 800px;
  position: relative;
}

.modal-close {
  position: absolute;
  top: -10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.product-item {
  color: #3d4586;
  font-size: 14px;
  font-weight: 400;
}

/* end */

.order_deletion_tracking {
  width: 100%;
  max-width: 800px;
  background: #ffffff;
  width: 100%;
  border: 10px solid rgba(61, 69, 134, 1);
  border-radius: 12px;
}

.order_id_style_en {
  width: 100%;
  max-width: 178px;
  font-size: 18px;
  color: rgba(61, 69, 134, 1);
  border-bottom: 1px solid rgba(61, 69, 134, 1);
  line-height: 0.8;
}

.order_id_style_fn {
  width: 100%;
  max-width: 220px;
  font-size: 18px;
  color: rgba(61, 69, 134, 1);
  border-bottom: 1px solid rgba(61, 69, 134, 1);
  line-height: 0.8;
}

.order_id_style_du {
  width: 100%;
  max-width: 178px;
  font-size: 18px;
  color: rgba(61, 69, 134, 1);
  border-bottom: 1px solid rgba(61, 69, 134, 1);
  line-height: 0.8;
}

.edit_order_deletion_btn {
  width: 100%;
  max-width: 130px;
  background: #3d4586;
  color: #ffffff;
  border-radius: 20px;
}

.edit_order_deletion_btn_2 {
  width: 100%;
  max-width: 130px;
  background: #ffffff;
  color: #3d4586;
  border: 2px solid #3d4586;
  border-radius: 20px;
}

.edit_order_deletion_btn_2:hover {
  background: #3d4586;
  color: #ffffff;
  border: 0;
}

.line_height {
  line-height: 1;
}

.checkout-pickup {
  background: #ffffff;
  width: 100%;
  border: 2px solid rgba(61, 69, 134, 1);
  border-radius: 20px;
}

.checkout-pickup-title {
  width: 100%;
  height: 38px;
  border-radius: 17px 17px 0 0;
  background: rgba(61, 69, 134, 1);
  color: #ffffff;
  font-family: "Galano Grotesque Alt", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: center;
}

.checkout-heading {
  width: 50%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.checkout-pickup-outer-title {
  font-family: "Galano Grotesque Alt", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24.3px;
  text-align: center;
  color: #3d4586;
}

/* .white_circle {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: linear-gradient(180deg, #8ae3e8 0%, #34a6ac 100%);
  transform: rotate(-90deg);
} */

.delete_style:hover {
  text-decoration: underline;
  cursor: pointer;
}

.secondaryBtnStyle {
  width: 100%;
  max-width: 230px;
  background: #ffffff;
  color: #3d4585;
  border: 2px solid #3d4585;
}

.secondaryBtnStyle:hover {
  background: #3d4585;
  color: #ffffff;
}

.progress_circle {
  width: 24px;
  height: 24px;
  background: #ffffff;
  border: 3px solid #8ae3e8;
  border-radius: 100%;
}

/* .progress_circle_active {
  background: #8ae3e8;
} */

.progress_circle_active {
  position: relative;
}

.progress_circle_active_issue {
  position: relative;
}

.progress_circle_active::before {
  content: "✔";
  position: absolute;
  background: #34a6ac;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -2.5px 0 0 -2.5px;
}

.progress_circle_active_issue::before {
  content: "X";
  position: absolute;
  background: rgba(225, 192, 255, 1);
  color: white;
  border: 0;
  border-radius: 50%;
  font-size: 18px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -2.5px 0 0 -2.5px;
}

.progress_line {
  width: 120px;
  border-top: 3px dashed #8ae3e8;
}

.progress_line_active {
  border-top: 3px solid #34a6ac;
}

/* header style */

.headerDropdown {
  top: 30px;
  right: 0;
  min-width: 230px;
  border: 3px solid #3d4586;
  border-radius: 12px;
}

.display_none_descktop {
  display: none;
}

.display_none_mobile {
  display: block;
}

.nav-item .active {
  border-bottom: 2px solid #fff;
}

.paddbzero {
  padding-bottom: 0;
}

.paddx40px {
  padding-right: 40px;
  padding-left: 40px;
}

/* profile page style */

.box_main {
  background: #fff;
  border-radius: 14.5px;
  overflow: hidden;
}

.box-header {
  background: #7480e0;
}

.box-header {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
}

.box_main {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
}

.nav-tabs.profile_tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  /* border-bottom: 1px solid  !important; */
  font-weight: bold;
}

.rounded_25px {
  border-radius: 25px !important;
}

.rounded_30px {
  border-radius: 30px;
}

.rounded_10px {
  border-radius: 10px;
}

.close_2 {
  position: absolute;
  background: #fff !important;
  border: 10px solid #414882;
  border-radius: 50px;
  right: -30px;
  top: -30px;
  padding: 10px;
  z-index: 1000;
}

.close {
  position: absolute;
  background: #fff !important;
  border: 10px solid #414882;
  border-radius: 50px;
  right: -30px;
  top: -30px;
  padding: 10px;
  z-index: 1000;
}

.cross_addres {
  position: absolute;
  top: -30px;
  right: -6px;
}

.close_confirm {
  position: absolute;
  color: white;
  border: 7px solid #414882;
  border-radius: 50px;
  right: -28px;
  top: -24px;
  z-index: 1000;
}

.border_color {
  border: 10px solid #414882;
  border-radius: 30px;
  padding: 0 50px 28px;
}

.border_color_btn {
  width: 250px;
  font-size: 20px;
  border: 5px solid #414882;
  border-radius: 10px;
  padding: 6px 20px;
}

.cursor_pointer {
  cursor: pointer;
}

.max_height_38px {
  max-height: 38px;
}

.w-and-h {
  width: 66px;
  height: 34px;
}

.pymnt_mdl_act_prog {
  background: #3469a5;
  border-color: #3469a5;
}

.mastercard_bg {
  background: #3d4586 !important;
}

.details_style {
  font-weight: 600;
  color: #3d4586;
}

.gradian_btn {
  background: linear-gradient(
    89.98deg,
    #7480e0 0.02%,
    #3d4586 100.72%
  ) !important;
  border-radius: 14.5px !important;
  border: none;
  color: #fff !important;
}

.payment_btn {
  border-radius: 4px;
  border: none;
  color: #fff !important;
}

.woshwallet {
  font-weight: 600;
  background: linear-gradient(90deg, #34a6ac 0%, #3d4b89 100%);
  border: 1px solid #19dbcf;
}

.border_payment {
  border: 3px solid #3d4586;
  padding: 80px;
}

.border_payment_color {
  border: 1px solid #3d4586;
}

.border_earn_credit {
  background: #ffffff;
  border: 3px solid #3d4586;
  box-shadow: 3px 3px rgb(0 0 0 / 10%);
  padding: 30px 80px;
  /* z-index: 1; */
}

.position_style {
  top: 40%;
  right: 52px;
}

.delete_account_modal {
  background: #ffffff;
  border: 5px solid #3d4586;
  border-radius: 15px;
  padding: 80px;
  z-index: 1;
}

.delete_account_btn {
  background: #ffffff;
  width: 150px;
  border: 3px solid #3d4586;
  border-radius: 8px;
  padding: 80px;
}

.woshwallet_new {
  font-weight: 600;
  background: linear-gradient(90deg, #34a6ac 0%, #3d4b89 100%);
  border-radius: 4px;
  border: 0;
}

.w_50_thank {
  width: 50%;
}

.woshwallet_new_2 {
  font-weight: 600;
  background: linear-gradient(90deg, #34a6ac 0%, #3d4b89 100%);
  border-radius: 0 4px 4px 0;
  border: 0;
}

.woshwallet_border {
  font-weight: 600;
  border: 2px solid #34a6ac;
  border-radius: 5px;
}

.text_bold {
  font-weight: 800;
}

.woshwallet_border_new {
  border-left: 2px solid #34a6ac;
  border-radius: 4px 0 0 4px;
  border-top: 2px solid #34a6ac;
  border-bottom: 2px solid #34a6ac;
  border-right: 0;
}

.woshwallet_border_new:hover {
  border-left: 2px solid #34a6ac;
  border-radius: 4px 0 0 4px;
  border-top: 2px solid #34a6ac;
  border-bottom: 2px solid #34a6ac;
  border-right: 0;
}

.mastercard {
  background: #000;
}

.payment_amount {
  font-size: 18px;
  line-height: 18px;
}

.addmethod,
.addmethod:hover {
  color: #3d4586 !important;
  background: #e7e9ff;
  font-weight: 600;
}

.fontsize_14px {
  font-size: 14px;
}

.box_position {
  left: 50px;
  bottom: 50px;
}

.width_420px {
  width: 350px;
}

.heading-line_color {
  width: 50px;
  height: 1px;
  float: left;
  background: #3d4586;
}

/*  login screen  */

.logo {
  /* position: absolute; */
  margin: 30px;
}

.login_logo {
  height: intrinsic;
  top: 30px;
  left: 40px;
}

.login_main {
  /*background-image: linear-gradient(to bottom right, #3188A1, #414882, #414882);*/
  /*border: 1px solid #E7E9FF;*/
  /*box-sizing: border-box;*/
  /*box-shadow: 0px -6px 4px rgba(0, 0, 0, 0.07);*/
  /*border-radius: 8px;*/
}

.main-title {
  color: #3d4586;
}

.disabled {
  opacity: 0.5;
}

.heading-line {
  width: 11%;
  height: 1px;
  float: left;
  background: #3d4586;
}

.link_with_social {
  box-shadow: 0px 4px 9px rgba(255, 255, 255, 0.25);
  background: #fff !important;
  color: #414882 !important;
  border-radius: 20px !important;
  border: 1px solid #414882 !important;
}

.height_16px {
  height: 16px;
}

/* .form_input_style {
  border: 1px solid #fff;
  border-radius: 10px;
  color: #fff !important;
} */

.form-control:focus {
  box-shadow: none;
}

input:focus {
  color: #fff;
  box-shadow: none;
}

#login_form ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #96a0bb !important;
  opacity: 1;
  /* Firefox */
}

#login_form :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #96a0bb !important;
}

#login_form ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #96a0bb !important;
}

.whitish {
  color: #96a0bb;
}

.white {
  color: #fff !important;
}

.color-red {
  color: red;
}

.fontsize_17px {
  font-size: 17px;
}

#exampleInputPassword1 ::before {
  width: 100px;
  height: 20px;
  background: #fff;
  top: 0;
  right: 0;
}

.mainheading {
  /* font-family: Poppins; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #3d4586;
}

.owl-carousel.owl-theme#address_result .owl-nav {
  margin-top: 10px !important;
}

.owl-carousel.owl-theme#address_result .owl-nav .owl-next,
.owl-carousel.owl-theme#address_result .owl-nav .owl-prev {
  background: #e7e9ff;
  color: #3d4586;
}

.owl-carousel#address_result .owl-stage-outer {
  margin-left: 0;
}

.info_bg {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255),
    rgba(61, 69, 134),
    rgba(61, 69, 134)
  );
  padding: 0px 8px;
}

#address_result .search_option {
  margin-right: 0;
  border-left: 4px solid #3d4586;
  background: #e7e9ff;
}

#address_result .search_option h3,
#address_result .search_option p {
  color: #3d4586;
}

.search_option {
  height: 160px;
  width: 240px;
  cursor: pointer;
  margin-right: 70px;
  border-left: 4px solid #19dbcf;
  background: rgb(160 193 219 / 17%);
}

.search_option:hover {
  background: rgb(150 192 226 / 21%);
}

/*owl crousal*/
.owl-theme .owl-dots {
  display: none;
}

.owl-carousel .owl-stage-outer {
  margin-left: 70px;
}

/* .owl-carousel.owl-drag .owl-item {
  width: 260px !important;
  margin-right: 60px !important;
} */

.carosal_w_75 {
  width: 85% !important;
}

.owl-carousel.owl-theme .owl-nav .owl-next {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  outline: none;
  font-size: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all linear 0.2s;
  /* background: #3d4586; */
  background: transparent;
  border: 3px solid #3d4586;
  box-sizing: border-box;
  box-shadow: 0px 4px 19px rgba(255, 255, 255, 0.26);
  color: #3d4586;
  margin-left: 30px;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background: #3d4586 !important;
  color: #fff !important;
}

/* .owl-theme .owl-nav button span {
  height: 42px;
} */
.owl-carousel.owl-theme .owl-nav .owl-prev {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  outline: none;
  font-size: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all linear 0.2s;
  /* background: #3d4586; */
  background: transparent;
  border: 3px solid #3d4586;
  box-sizing: border-box;
  box-shadow: 0px 4px 19px rgba(255, 255, 255, 0.26);
  color: #3d4586;
  margin-right: 30px;
}

.paginate-ctn .round-effect:first-child,
.paginate-ctn .round-effect:last-child {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  outline: none;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all linear 0.2s;
  background: #3d4586;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 19px rgba(255, 255, 255, 0.26);
  color: #fff;
  /* margin-right: 30px; */
}

.paginate-ctn .round-effect,
.paginate-ctn .round-effect {
  width: 40px;
  height: 40px;
  outline: none;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all linear 0.2s;
  background: transparent;
  color: #9ea2c2;
  margin-left: 10px;
  margin-right: 10px;
  /* margin-right: 30px; */
}

.round-effect.active {
  color: #3d4586 !important;
}

.search_option h3 {
  color: #fff;
  /* font-family: Poppins; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.search_option p {
  font-family: "galano_grotesqueregular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.owl-theme .owl-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px !important;
  position: absolute;
  top: 20px;
}

.footer h3 {
  font-family: "galano_grotesque_altregular";
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
}

.footer p {
  font-family: "galano_grotesque_altregular";
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-decoration-line: underline;
  color: #ffffff;
}

.fill_btn {
  background: #414882;
  border-radius: 8px;
  color: #fff;
}

.fill_btn_del {
  background: #e1c3ff;
  border-radius: 8px;
  color: #fff;
}

.fill_btn_del:hover {
  color: #fff;
}

.fill_btn_search {
  background: rgb(230, 233, 255);
  border-radius: 0 10px 10px 0;
  color: #414882;
  font-weight: 600;
  height: 50px;
  width: 140px;
}

.fill_btn_new {
  background: #414882;
  border-radius: 8px;
  color: #ffffff;
  font-weight: 600;
}

.fill_btn_new:hover {
  color: white;
  background: #414882;
}

.fill_btn:hover {
  color: white;
  background: #414882;
}

.fill_btn_again {
  background: rgb(226, 234, 241);
  border-radius: 8px;
  color: #ffffff;
  font-weight: 600;
}

.pagination_orders {
  color: #9ea2c2 !important;
}

.btn_connect {
  font-size: 12px;
}

.bg_btn_add {
  background: linear-gradient(90.03deg, #7480e0 0.86%, #3d4586 99.97%);
}

.m_7 {
  margin-top: 7rem !important;
}

.heading-line-color {
  width: 50px;
  height: 1px;
  float: left;
  background: #e5e5e5;
}

.bg-light-color {
  background: #e7e9ff;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: #7480e0 !important;
}

.lh_1 {
  line-height: 1;
}

.text_color {
  color: #3d4586;
}

.w-85 {
  width: 85% !important;
}

.border_btn {
  border: 1px solid linear-gradient(90deg, #34a6ac 0%, #3d4b89 100%);
  border-radius: 8px;
  color: #fff !important;
}

.edit_btn {
  background: #d9dbee;
  border-radius: 8px;
  color: #414882;
  font-weight: 600;
}

.hide_show_btn {
  line-height: 0;
}

.color_white,
.theme_color,
.body .first_tabs.nav_new_design#myTab .nav-item .nav-link.active p {
  color: #fff !important;
}


.info-icon {
  background: #414882;
  color: #ffffff;
  border-right: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  border-radius: 8px 0 8px 0;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, border-radius 0.3s ease-in-out;
}

.info-icon:hover {
  background: #ffffff;
  color: #414882;
  border-radius: 4px 0 5px 0;
}

.hover-description {
  text-align: center;
  color: white;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.theme_color_z_index {
  background-color: rgb(61, 69, 134);
  border-radius: 5px;
  opacity: 1;
  transition: background-color 0.3s linear, opacity 0.3s linear;
}

.theme_color_z_index:hover .hover-description {
  opacity: 1;
  transition: opacity 0.3s linear 0.3s;
  background-color: rgb(61, 69, 134);
}


.fade_custom {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  cursor: pointer;
}

.theme_bg {
  background: #3d4586 !important;
}

.theme_green_bg {
  background: #34a6ac;
}

.theme_color,
.body .first_tabs.nav_new_design#myTab .nav-item .nav-link p {
  color: #3d4586 !important;
  font-family: "galano_grotesqueregular";
}

.padd_content {
  padding: 0 88px;
}

.typecode {
  width: 100%;
  display: flex !important;
  flex-direction: row;
  justify-content: center;
}

.typecode input {
  width: 60px;
  height: 80px;
  margin: 0 13px;
  border: 0;
  border-radius: 10px !important;
  font-size: 40px;
  line-height: 90px;
  text-align: center;
  color: #00c395;
  font-weight: 800;
  background: #e7f1fa;
}

.typecode input::-webkit-outer-spin-button,
.typecode input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.typecode input[type="number"] {
  -moz-appearance: textfield;
}

.typecode input:first-child {
  margin-left: 0;
}

.typecode input:last-child {
  margin-right: 0;
}

.z_index_two {
  z-index: -10000 !important;
}

.theme_green_color {
  color: #34a6ac;
}

.theme_green_color:hover {
  color: #34a6ac;
}

.on_focus:focus-visible {
  outline: none;
}

.font_style {
  /* font-family: sans-serif; */
  font-family: "galano_grotesquesemibold";
  font-weight: 600;
}

.reset_pass {
  display: flex;
  flex-direction: column;
  width: max-content;
  margin: 0 auto;
}

.form_input_style.theme_color {
  color: #3d4586 !important;
}

.form_input_style.theme_color::placeholder,
#placeholderColor::placeholder,
#placeholderColor::-webkit-input-placeholder {
  color: #3d4586;
  opacity: 0.6;
}

.lable_style {
  font-size: 14px;
  border-radius: 25px;
  top: -11px;
}

.footer_btn {
  bottom: 40px;
  left: 60px;
}

.footer_btn a {
  color: #3d4586;
}

.check_lable {
  font-size: 13px;
  color: #96a0bb;
}

#login_form .form-check-input:checked {
  background-color: #2a94ac;
  border-color: #2a94ac;
}

#login_form .form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
}

#login_form .form-control:focus {
  box-shadow: none;
}

.form_input_style_with_bg {
  color: #fff !important;
  resize: none;
  border: #96a0bb;
  background: #96a0bb !important;
  border-radius: 8px;
}

#login_form .form_input_style_with_bg::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b7bed2;
  opacity: 1;
  /* Firefox */
}

#login_form .form_input_style_with_bg:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b7bed2;
}

#login_form .form_input_style_with_bg::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b7bed2;
}

/* width */
.form_input_style_with_bg::-webkit-scrollbar {
  width: 0;
}

.error_text {
  font-weight: 600;
}

.mr_8px {
  margin-right: 8px;
}

.agree_btn {
  background: #d9dbee;
}

/* use wash screen style */
.use_wash_boxes {
  border: 8px solid #3d4586;
  border-radius: 20px;
}

.pick_delivery_heading {
  font-size: 20px;
  line-height: 27px;
  color: #3d4586;
}

.checked_box.use_wash_boxes {
  border: 8px solid #8ae3e8;
}

.checked_box .d-none {
  display: block !important;
}

.use_wash_boxes:hover {
  border: 8px solid #8ae3e8;
}

.use_wash_boxes:hover .d-none {
  display: block !important;
}

.use_wosh_check {
  top: -20px;
  right: -20px;
  color: #8ae3e8;
  border-radius: 50%;
  background: transparent;
  line-height: 0;
}

.use_wosh_check .fa {
  font-size: 50px;
  line-height: 44px;
}

.btn-custom-onboard {
  color: #fff !important;
  background: linear-gradient(to right, #737fdf, #7584e0, #7489c9);
  font-weight: 700;
  padding: 8px 120px;
}

.btn-custom-onboardd {
  color: #fff !important;
  background: linear-gradient(to right, #737fdf, #7584e0, #7489c9);
  font-weight: 700;
  padding: 8px 70px;
}

.mt_100px {
  margin-top: 150px;
}

.outline_none {
  outline: none !important;
}

.theme_color_bg {
  background: #3d4586;
}

.theme_color {
  color: #3d4586;
}

.theme_color_icon {
  color: #3d4586;
}

.theme_color_light_blue {
  color: #7480e0;
}

.theme_bg_btn,
.theme_bg_btn {
  background: #e7e9ff !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.theme_border {
  border: 1px solid #3d4586;
}

.theme_2pxborder {
  border: 2px solid #3d4586;
}

.theme_2pxborder:hover {
  border: 2px solid #3d4586;
}

.theme_10pxborder {
  border: 10px solid #3d4586;
}

.theme_input {
  color: #3d4586 !important;
  outline: none;
}

.theme_input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #3d4586;
  opacity: 1;
  /* Firefox */
  text-align: center;
}

.theme_input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #3d4586;
  text-align: center;
}

.theme_input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #3d4586;
  text-align: center;
}

.seagreen_color {
  color: #8ae3e8 !important;
}

.seagreen_border_color {
  border: 2px solid #8ae3e8 !important;
}

.w-50px {
  width: 50px !important;
}

.w_65per {
  width: 65%;
}

.height_100px {
  height: 100px;
}

/* get bags page style  */
.h_189px {
  height: 189px;
}

.w_360px {
  width: 360px;
}

.w_180px {
  width: 180px;
}

.w_100per {
  width: 100% !important;
}

/* thank yopu for registration style */
.registration-text {
  text-align: center;
}

.registration-img {
  margin-top: 90px;
}

.btn-custom-register {
  color: #4351b5;
  background: #e7e9ff;
  font-weight: 700;
  padding: 8px 120px;
}

.my_tab_style {
  width: 100%;
  /* height: 99px; */
  background: #e7e9ff;
  border-radius: 8px;
}

/* width */
.my_tab_style::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/*  modal style  */
.w_45per {
  width: 45%;
}

.modal_main {
  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.modal_style {
  font-size: 24px;
  line-height: 27px;
  text-align: center;
  color: #3d4586;
}

.modal_btn_bordered {
  border: 1px solid #3d4586;
  color: #3d4586;
}

.modal_btn_filled {
  background: #3d4586;
  border: 1px solid #3d4586;
  color: #fff;
}

.modal_btn_trycontect {
  color: #3d4586;
  background: #e7e9ff;
  box-shadow: 0px 4px 9px rgba(255, 255, 255, 0.25);
  border-radius: 5px;
}

/* home style */
.homebody {
  background-image: linear-gradient(to bottom right, #737fde, #3d4586, #3d4586);
  padding-bottom: 30px;
}

/* tab style */
.body .first_tabs#myTab .nav-item {
  /* width: 125px; */
  min-height: 100px;
  color: #3d4585;
}

.body .first_tabs#myTab .nav-item .active {
  width: 120px;
  min-height: 100px;
  /* background: linear-gradient(120.72deg, #8ae3e8 0%, #7480e0 100%); */
  background: #3d4586;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: #fff;
  border: 0;
  font-size: 14px;
  font-weight: 800;
}

.body .first_tabs#myTab .nav-item a {
  width: 100%;
  /* height: 100%; */
  color: #3d4585;
  font-size: 11px;
  text-align: center;
}

.body .second_tabs {
  border: 0 solid #e7e9ff;
  box-sizing: border-box;
  /* filter: drop-shadow(0px 4px 4px rgba(61, 69, 134, 0.33)); */
  border-radius: 8px;
  background: #e7e9ff;
}

.body .second_tabs#myTab {
  width: fit-content;
  flex-direction: row;
  /* overflow-x: auto; */
  /* overflow-y: hidden; */
  display: flex;
  flex-wrap: nowrap;
}

.body .second_tabs#myTab .nav-item .active,
.body .second_tabs#myTab .nav-item:hover {
  background: #3d4586 !important;
  border: 0 !important;
  border-radius: 8px;
  color: #3d4586;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}

.body .second_tabs#myTab .nav-item .nav-link {
  color: #3d4586;
  border: 0 !important;
  border-radius: 0;
  cursor: pointer;
}

.body #hover_none .second_tabs#myTab .nav-item:hover {
  background: transparent !important;
  color: #3d4586;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}

.body #hover_none .second_tabs#myTab .nav-item:hover h3 {
  color: #3d4586 !important;
}

.body #hover_none .second_tabs#myTab .nav-item:hover .active h3 {
  color: #fff !important;
}

.body .second_tabs#myTab .nav-item .active h3,
.body .second_tabs#myTab .nav-item:hover h3,
.body .second_tabs#myTab .nav-item .active p,
.body .second_tabs#myTab .nav-item:hover p {
  color: #fff !important;
}

.body .second_tabs.new_design#myTab .nav-item .nav-link {
  border-radius: 8px;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.theme_text {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.box {
  background: #ffffff;
  border-radius: 6px;
  border: 0.856802px solid #3d4586;
  border-radius: 8px;
}

.w_157px {
  width: 157px;
}

.h_100per {
  height: 100%;
}

.product_box_main {
  width: 22%;
}

.bag_custom_center {
  align-items: center !important;
}

.bg_custom {
  background-color: #e7e9ff !important;
}

.bg_custom h3 {
  background: #e7e9ff !important;
}

.date_color {
  color: #d9dbee;
}

.box h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #3d4586;
  background: #ffffff;
  border-radius: 6px;
}

.box p {
  font-style: normal;
  font-size: 15px;
  line-height: 25px;
  color: #3d4586;
}

.icons .nav-item a img.first {
  display: block;
}

.icons .nav-item a:hover img:first-child {
  display: none;
}

.icons .nav-item a img.second {
  display: none;
}

.icons .nav-item a.active img.second,
.icons .nav-item a:hover img.second {
  display: block;
}

.icons .nav-item a.active img.first,
.icons .nav-item a:hover img.first {
  display: none;
}

.h_max_content {
  height: max-content;
}

.px_70px {
  padding-right: 35px;
  padding-left: 35px;
}

.margin_right {
  margin-right: 55px;
}

.cat_font_size {
  font-size: 11px;
}

.border_hover:hover {
  border-color: transparent !important;
  cursor: pointer;
}

.font_14px {
  font-size: 14px !important;
}

.font_12px {
  font-size: 12px !important;
}

.line_dark {
  background: #3d4586 !important;
}

.h_200px {
  min-height: 30vh;
  max-height: 30vh;
}

.important_margin {
  padding-bottom: 7rem !important;
}

.border_bottom_dark {
  border-bottom: 1px solid #3d4586;
}

/*.border_1px_light {
	border: 1px solid #E7E9FF;
}*/

.checkoutMargin {
  margin-top: 4rem !important;
}

.checkout_btn {
  background: linear-gradient(90deg, #34a6ac 0%, #3d4586 74.47%);
  border: 2px solid #e7e9ff;
  box-sizing: border-box;
  border-radius: 0px 0px 8px 8px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.94);
}

.checkout_btn:hover {
  color: rgba(255, 255, 255, 0.94);
  border-color: #3d4586;
}

.btn_text {
  color: #d3d3d791;
}

.btn_text:hover {
  color: #d3d3d791;
  border: 2px solid #e7e9ff;
}

/* order page style */
#body .second_tabs.order_tabs#myTab {
  background-color: white;
  width: max-content;
  border-radius: 25px;
  border: 2px solid #3d4586;
  box-sizing: border-box;
  /* filter: drop-shadow(0px 4px 4px rgba(61, 69, 134, 0.33)); */
}

#body .second_tabs.order_tabs#myTab .nav-item .nav-link {
  min-width: 200px;
  color: #3d4586;
  border: 0 !important;
  border-radius: 25px;
  text-align: center;
}

.scroll_horizon:hover {
  padding: 0 !important;
}

.scroll_horizon {
  width: auto !important;
  flex-wrap: nowrap;
  cursor: pointer;
  z-index: 1;
  overflow-x: scroll;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.widthFitContent {
  width: max-content !important;
}

.min_width {
  min-width: 214px !important;
}

#body .second_tabs.order_tabs#myTab .nav-item .active {
  border-radius: 25px;
  color: #fff !important;
}

#body .second_tabs.order_tabs#myTab .nav-item:hover {
  background: transparent;
  border-radius: 25px;
}

#body .second_tabs.order_tabs#myTab .nav-item:hover h3 {
  color: #fff;
}

#body .second_tabs.order_tabs#myTab .nav-item .active h3 {
  color: #3d4586;
}

.w_180px {
  width: 180px !important;
}

.w_170px {
  width: 170px;
}

.w_20per {
  width: 20%;
}

.W_95per {
  width: 95%;
}

.outline-0 {
  outline: 0 !important;
  box-shadow: none !important;
}

#body .dropdown-toggle::after {
  border: 0;
}

.theme_color_light {
  color: rgba(255, 255, 255, 0.65) !important;
}

/* past order tab */
.past_order_box {
  width: 45%;
  background: #fff;
  border-radius: 8px;
  /* overflow: hidden; */
}

.mt_auto {
  margin-top: auto !important;
}

.btn_position {
  position: absolute;
  bottom: 0;
  right: -200px;
}

.btn_position_fn {
  position: absolute;
  bottom: 0;
  right: -280px;
}

.btn_position_du {
  position: absolute;
  bottom: 0;
  right: -240px;
}

.border_lrt {
  border-left: 2px solid lightblue;
  border-right: 2px solid lightblue;
}

.past_order_box_header {
  background: #3d4586;
}

.blue {
  color: #7480e0;
}

.bg_F4F4F4 {
  background: #f4f4f4;
}

/*  login screen  */

.login_logo {
  top: 30px;
  left: 30px;
}

/* .login_main {
	/*background-image: linear-gradient(to bottom right, #3188A1, #414882, #414882);*/
/*border: 1px solid #E7E9FF;*/
/*box-sizing: border-box;*/
/*box-shadow: 0px -6px 4px rgba(0, 0, 0, 0.07);*/
/*border-radius: 8px;
} */

.main-title {
  color: #3d4586;
}

.title {
  font-size: 32px;
  font-weight: 800;
}

.heading-line {
  /* width: 50%; */
  height: 1px;
  float: left;
  background: #3d4586;
}

.link_with_social {
  box-shadow: 0px 4px 9px rgba(255, 255, 255, 0.25);
}

.height_16px {
  height: 16px;
}

.form_input_style_dark {
  border: 1px solid #414882 !important;
  border-radius: 10px;
  color: #414882 !important;
}

.form_input_style {
  border: 1px solid #3d4586;
  border-radius: 10px;
  color: #3d4586;
}

.form_input_style::placeholder {
  color: #3d4586;
  opacity: 60%;
}

input:focus {
  color: black;
}

#login_form ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #96a0bb;
  opacity: 1;
  /* Firefox */
}

#login_form :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #96a0bb;
}

#login_form ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #96a0bb;
}

.whitish {
  color: #96a0bb;
}

.white {
  color: #fff !important;
}

.fontsize_17px {
  font-size: 17px;
}

#exampleInputPassword1 ::before {
  width: 100px;
  height: 20px;
  background: #fff;
  top: 0;
  right: 0;
}

.border_btn {
  border: 1px solid #414882;
  border-radius: 8px;
  color: #414882 !important;
}

.edit_btn {
  background: #d9dbee;
  border-radius: 8px;
  color: #414882;
  font-weight: 600;
}

.hide_show_btn {
  line-height: 0;
}

.color_white {
  color: #fff;
}

.lable_style {
  font-size: 14px;
  border-radius: 25px;
  top: -11px;
}

.footer_btn {
  bottom: 40px;
  left: 60px;
}

.footer_btn a {
  color: #3d4586;
}

.check_lable {
  font-size: 13px;
  color: #96a0bb;
}

#login_form .form-check-input {
  /* background: ; */
}

#login_form .form-check-input:checked {
  background-color: #2a94ac;
  border-color: #2a94ac;
}

#login_form .form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
}

#login_form .form-control:focus {
  box-shadow: none;
}

.form_input_style_with_bg {
  color: #fff !important;
  resize: none;
  border: #96a0bb;
  background: #96a0bb !important;
  border-radius: 8px;
}

#login_form .form_input_style_with_bg::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b7bed2;
  opacity: 1;
  /* Firefox */
}

#login_form .form_input_style_with_bg:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b7bed2;
}

#login_form .form_input_style_with_bg::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b7bed2;
}

/* width */
.form_input_style_with_bg::-webkit-scrollbar {
  width: 0;
}

.error_text {
  font-weight: 600;
}

.mr_8px {
  margin-right: 8px;
}

.agree_btn {
  background: #d9dbee;
}

/* use wash screen style */
.use_wash_boxes {
  border: 8px solid #3d4586;
  border-radius: 20px;
}

.text_blue {
  color: #3d4586;
}

.pick_delivery_heading {
  font-size: 20px;
  line-height: 27px;
  color: #3d4586;
}

.checked_box.use_wash_boxes {
  border: 8px solid #8ae3e8;
}

.use_wash_boxes:hover {
  border: 8px solid #8ae3e8;
}

.use_wash_boxes:hover .d-none {
  display: block !important;
}

.checked_box .d-none {
  display: block !important;
}

.use_wosh_check {
  top: -20px;
  right: -20px;
  color: #8ae3e8;
  border-radius: 50%;
  line-height: 0;
}

.use_wosh_check .fa {
  font-size: 50px;
  line-height: 44px;
}

/* clender Modal */
.clender_date li,
.scheldue_width li {
  width: 18%;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px 5px;
  border-bottom: 1px solid #e7e9ff;
}

/* .clender_active {
  background: black;
  border-radius: 4px;
} */
.clender_active {
  background: #e7e9ff;
  border-radius: 4px;
}

.scheldue_width li {
  width: 31% !important;
}

.w25per_des {
  width: 25%;
}

.min_heiight60px {
  min-height: 60px;
}

.min_height180px {
  min-height: 180px;
}

.w_80per {
  width: 85%;
}

.w_50 {
  width: 50%;
}

.pickupDeliver:hover {
  /* background-color: #f4f4f4e5 !important; */
  opacity: 0.8;
  cursor: pointer;
}

.payment_border {
  border: 3px solid #19dbcf !important;
}

.cursor:hover {
  cursor: unset;
}

.w_75 {
  width: 75%;
}

.w_75_new {
  width: 65%;
}

.w_35_new {
  width: 35%;
}

.w_85 {
  width: 85%;
}

.vertical_bottom_desk {
  display: flex;
  align-items: flex-end;
}

.px15_radius {
  border-radius: 12px;
}

.link_with_social .fa {
  margin-right: 10px;
}

.google_inner_padd div,
.google_inner_padd span {
  padding: 0 !important;
  font-weight: 600 !important;
  background: transparent !important;
}

.w_35per {
  width: 35%;
}

.centered {
  position: absolute;
  top: 8%;
  left: 25%;
  margin-right: 25%;
  transform: translate(25%, 15%);
}

.minh_301px {
  min-height: 301px;
}

.lh_28px {
  line-height: 60px;
}

.bg_light {
  background-color: #e7e9fe;
}

#search::placeholder {
  font-size: 12px;
}

.form-control#search {
  padding-left: 0px !important;
}

.textdecoration_none {
  text-decoration: none !important;
}

.w_25 {
  width: 25%;
}

.ErrorMessage {
  position: unset !important;
}

.vl {
  border-left: 2px solid #3d4586;
  height: 120px;
}

.wallet_credit_card {
  background-color: transparent;
  border: 1px solid #3d4586;
}

.wallet {
  font-weight: 600;
  background: linear-gradient(90deg, #34a6ac 0%, #3d4b89 100%);
}

.bg-btn-add-color {
  background: #3d4586;
}

.choose_methid_color_h3 {
  color: #3d4586;
}

.choose_method_modal_border_rounded {
  border-radius: 40px;
}

.mt_5 {
  margin-top: 70px;
}

.headerDropdown_screen {
  top: 30px;
  right: 0;
  min-width: 300px;
  border: 3px solid #3d4586;
  border-radius: 12px;
}

.headerDropdown_screen_2 {
  top: 8px;
  right: 0;
  min-width: 300px;
  border-left: 3px solid #3d4586;
  border-right: 3px solid #3d4586;
  border-bottom: 3px solid #3d4586;
  border-top: 0;
  border-radius: 0px 0px 12px 12px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
}

/* @media only screen and (max-width: 1200px) {
  .vl {
    border-left: none;
    height: 0;
  }
} */

.max_height {
  height: 68px;
}

.margin-top-7 {
  margin-top: 7rem !important;
}

.shadow_green {
  box-shadow: 0px 0px 0px 4px rgba(25, 219, 207, 0.75) !important;
  -webkit-box-shadow: 0px 0px 0px 4px rgba(25, 219, 207, 0.75);
  -moz-box-shadow: 0px 0px 0px 4px rgba(25, 219, 207, 0.75);
}

#light_placeholder::placeholder {
  opacity: 0.3;
}

.showInMobile {
  display: none;
}

.minwidth400px {
  position: absolute;
  min-width: 400px;
  top: 12px !important;
  border-radius: 19px !important;
}

.zIndexMain {
  z-index: 1;
}

.borderColorDark .dropdown__item {
  border-bottom: 1px solid #414882 !important;
}

.borderColorDark .dropdown__item:first-child {
  border-top: 1px solid #414882 !important;
}

.borderColorDark .dropdown__item:hover {
  color: #fff;
  background: #414882;
}

.minwidth400px.dropdown.active .dropdown__items {
  border-top: none;
}

.navIcons {
  min-width: 30px;
}

/* new design css */
.padding {
  padding-left: 0;
  padding-right: 40px;
}

#promotion_slider .MuiBox-root.jss7,
#promotion_slider .MuiBox-root.jss4,
#promotion_slider .MuiBox-root.jss20 {
  align-items: center;
}

#promotion_slider .MuiBox-root:nth-child(2) div,
#promotion_slider .MuiBox-root.jss20 .jss21,
#promotion_slider .MuiBox-root.jss4 .jss5,
#promotion_slider .MuiBox-root.jss7 .jss8 {
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2px solid #7480e0;
}

#promotion_slider .MuiBox-root.jss20 .jss21.jss22,
#promotion_slider .MuiBox-root.jss4 .jss5.jss6,
#promotion_slider .MuiBox-root.jss7 .jss8.jss9 {
  width: 10px;
  height: 10px;
  background-color: #7480e0;
  transition: 0.3;
}

.promotion_heading {
  color: #3d4585;
  font-size: 18px;
  font-family: "galano_grotesque_altmedium" !important;
  font-weight: 600;
  margin-left: 25px;
  margin-right: 8px;
  margin-top: 16px;
}

.regular_font {
  font-family: "galano_grotesque_altmedium" !important;
}

.promotion_line {
  width: 50px;
  height: 2px;
  background-color: #3d4585;
  margin-left: 25px;
  margin-top: 10px;
}

.promotion_text {
  color: #7580df;
  font-size: 13px;
  font-weight: bold;
  margin-left: 25px;
  margin-right: 10px;
  margin-top: 8px;
}

.show_in_responsive {
  display: none;
  /* z-index: 1; */
}

.hide_in_responsive {
  display: block;
}

.body .first_tabs.nav_new_design#myTab {
  border: 0;
  /* height: -webkit-fill-available; */
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  flex-wrap: nowrap;
  padding-bottom: 85px;
  width: 150px;
}

.body .first_tabs.nav_new_design#myTab .nav-item {
  width: 120px;
  font-family: "galano_grotesqueregular";
}

.body .first_tabs.nav_new_design#myTab .nav-item .active {
  /* height: inherit; */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: "galano_grotesquesemibold";
}

.body .first_tabs.nav_new_design#myTab .nav-item a {
  border: 0;
  cursor: pointer;
}

.body .first_tabs.nav_new_design#myTab .nav-item a.setradius {
  border-radius: 0 !important;
}

.reponsive_set {
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-wrap: nowrap;
}

.scroll_tab_content {
  max-height: 54vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 15px;
  margin-top: 40px;
}

.z_index-1 {
  z-index: -1 !important;
}

.z_index {
  z-index: 1000 !important;
}

.card_description {
  width: 100%;
  height: 220px;
  background-color: rgb(230, 233, 255);
  border-radius: 8px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: transparent !important;
  color: #3d4586 !important;
  border-bottom: 3px solid #3d4586 !important;
  font-weight: 800;
  border-radius: 0;
}

.color_text {
  color: #3d4586;
  font-weight: 800;
}

.scroll_tab_content_new_2 {
  max-height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 100px;
  margin-top: 10px;
}

.scroll_tab_content_new {
  max-height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
  /* padding-bottom: 100px; */
  margin-top: 45px;
}

.scroll_tab_content_new_edit_order {
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
  /* padding-bottom: 100px; */
  margin-top: 45px;
}

.scroll_header_dropdown {
  max-height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 10px;
}

.scroll_payment_content {
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 130px !important;
}

.slider_img {
  width: 120px;
  /* height: 120px; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px 0 0 5px;
}

.slider_img img {
  width: fit-content;
  height: 75px;
  margin: 0 !important;
}

.h_92vh {
  height: 90vh;
}

.position_absolute {
  position: absolute;
}

.h_100 {
  height: 100%;
}

.overflow_hidden {
  overflow: hidden;
}

.theme_shadow {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
}

.theme_shadow_slider {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

#promotion_slider
  .MuiBox-root
  .MuiContainer-disableGutters.MuiContainer-maxWidthLg {
  /* background: #e6e9ff; */
  /* width: 335px; */
  /* border-radius: 10px; */
  max-width: 100%;
}

.w_335px {
  width: 335px;
}

.maxw_335px {
  max-width: 335px;
}

.w_150px {
  width: 150px;
}

.w_90per {
  width: 90%;
}

.menu_spacing a {
  min-height: 50px;
  font-size: 16px;
  padding: 10px;
}

.flex_row {
  flex-direction: row;
}

.height_in_desktop_mobile {
  height: 90vh;
}

.height_in_desktop_mobile_edit_order {
  height: 80vh;
}

.w_in_mobile_desktop {
  width: 97%;
}

.nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-bN97Pc-sM5MNb {
  width: 100%;
}

.haAclf {
  width: 100%;
}

.S9gUrf-YoZ4jf,
.S9gUrf-YoZ4jf * {
  width: 100%;
}

/* responsive style start */
@media only screen and (max-width: 991px) {
  .showInMobile {
    display: block;
  }

  .w_50_thank {
    width: 100%;
  }

  .hiddenInMobile {
    display: none;
  }

  .height_in_desktop_mobile {
    height: auto;
  }

  .w_in_mobile_desktop {
    width: 100%;
  }

  .hiddenInMobile_footer {
    display: none !important;
  }

  .margin_in_mobile {
    padding: 0 84px;
  }

  /* .padding_in_mobile { */
  /* padding: 0 34px; */
  /* } */

  .mobile_screen {
    width: 100%;
    max-width: 100%;
    /* padding: 0 34px; */
  }

  .d_none_in_mobile {
    display: none;
  }

  .minwidth400px {
    min-width: 400px;
    /* min-width: 100%; */
    margin-bottom: 12px !important;
    position: absolute;
  }

  .min_width {
    text-align: left;
  }

  .padding {
    width: 90%;
    margin: 0 auto;
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    padding-right: calc(var(--bs-gutter-x) * 0.5);
  }

  .show_in_responsive {
    display: block;
  }

  .hide_in_responsive {
    display: none;
  }

  .margin_right {
    margin-right: 0;
  }

  .position_absolute {
    position: unset;
  }

  .h_100 {
    height: auto;
  }

  .overflow_hidden {
    overflow: unset;
  }

  .h_92vh {
    height: auto;
  }

  #navbarSupportedContent {
    text-align: center;
    margin: 0 25px;
  }

  .flex_row {
    flex-direction: column;
  }

  .middle_left {
    display: none;
  }

  .body .first_tabs#myTab .nav-item .active {
    min-width: auto;
    min-height: auto;
    border-radius: 5px;
  }

  .body .first_tabs#myTab .nav-item .active {
    width: auto;
  }

  .body .first_tabs#myTab .nav-item {
    color: #3d4585 !important;
  }

  .body .first_tabs#myTab .nav-item {
    color: #3d4585 !important;
  }

  .nav-tabs.profile_tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link {
    /* border-bottom: 1px solid  !important; */
    /* font-weight: bold; */
    color: #3d4585;
  }

  .fontInMobile {
    font-size: 12px;
  }

  /* .w_335px {
    width: 100%;
  } */
}

@media (min-width: 1000px) and (max-width: 1300px) {
  .font_in_profile {
    font-size: 12px;
    padding: 0 20px;
  }
}

@media (min-width: 1000px) and (max-width: 1264px) {
  .fontInMobile {
    font-size: 12px;
  }
}

@media (min-width: 1000px) and (max-width: 1200px) {
  .past_order_box {
    width: 50%;
  }
  .fontInMobile {
    font-size: 11px;
  }
}

@media (min-width: 1000px) and (max-width: 1200px) {
  .color_text {
    font-size: 14px;
  }
  .color_text_profile {
    font-size: 12px;
  }
}

@media (min-width: 1000px) and (max-width: 1060px) {
  .color_text {
    font-size: 12px;
  }
  .border_payment {
    padding: 30px 40px;
  }
}

@media (min-width: 935px) and (max-width: 1000px) {
  .past_order_box {
    width: 70%;
  }
  .fontInMobile {
    font-size: 11px;
  }
  .color_text {
    font-size: 11px;
  }
  .text_hidden {
    width: 96%;
  }
}

@media only screen and (max-width: 1020px) {
  .color_text {
    font-size: 12px;
  }
}

@media only screen and (max-width: 935px) {
  .w_60per {
    width: 65%;
  }

  .showInMobile {
    display: block;
  }

  .hiddenInMobile {
    display: none;
  }

  .past_order_box {
    width: 80%;
  }
}

@media only screen and (max-width: 991px) {
  .px_70px {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }

  .top_right {
    z-index: -1000;
  }
  .body .second_tabs#myTab {
    /* width: max-content; */
    width: auto;
    display: flex;
    justify-content: space-between;
  }

  .responsive_mobile_earn {
    width: 100%;
  }

  .headerDropdown_screen {
    /* right: -46px; */
  }

  .flex_wrap {
    flex-wrap: wrap;
  }

  .w_90per {
    width: 100%;
  }

  .border_earn_credit {
    padding: 30px 24px;
  }

  .color_text_profile {
    font-size: 14px;
  }

  .w_in_mobile {
    width: 100%;
  }

  .border_btn {
    width: 200px;
    font-size: 12px;
  }
  /*
  .fill_btn {
    width: 200px;
    font-size: 12px;
  } */

  .title {
    font-size: 23px;
    font-weight: 800;
  }

  .border_payment {
    padding: 20px 30px;
  }

  .woshwallet_new_2 {
    font-size: 12px;
  }

  .text_bold {
    font-size: 12px;
  }

  .middle_left_white {
    display: none;
  }

  .circle_left_middle_dot {
    display: none;
  }

  .p_desktop_mobile {
    font-size: 12px;
  }

  .text_hidden {
    width: 90%;
  }
}

@media only screen and (max-width: 770px) {
  .minwidth400px {
    min-width: 220px;
  }
  .my_tab_style {
    height: 99px;
  }
  .padd_in_mobile {
    padding: 20px;
    border-radius: 8px;
  }
}

@media only screen and (max-width: 540px) {
  .minwidth400px {
    min-width: 200px;
  }

  .dropdown_mid_in_mobile {
    display: none;
  }

  .dropdown_show_mid_in_mobile {
    display: block;
  }

  .margin_in_mobile {
    padding: 0 74px;
  }

  .padding_in_mobile {
    padding: 0 34px;
  }

  .mobile_screen {
    width: 100%;
    max-width: 100%;
    /* padding: 0 34px; */
  }

  .color_text {
    font-size: 14px;
  }

  .login_logo {
    display: none;
  }

  .headerDropdown_screen_2 {
    top: 20px;
  }

  .margin_botm {
    margin-bottom: 12px;
  }

  .position_style {
    top: 38%;
    right: 15px;
  }

  .cat_font_size {
    font-size: 12px;
    letter-spacing: 0.4px;
  }

  .mt_in_mobile {
    margin-top: 90px;
  }
}

@media only screen and (max-width: 740px) {
  .minwidth400px {
    min-width: 180px;
  }

  .margin_in_mobile {
    padding: 0 34px;
  }

  .padding_in_mobile {
    padding: 0;
  }

  .mobile_screen {
    width: 100%;
    max-width: 100%;
    /* padding: 0 34px; */
  }

  .position_style {
    top: 34%;
    right: 34px;
  }
}

@media only screen and (max-width: 340px) {
  .w_335px {
    width: 100%;
  }

  .maxw_335px {
    max-width: 100%;
  }

  .responsive_mobile_earn {
    width: 100%;
  }

  .headerDropdown_screen {
    right: -46px;
  }

  .minwidth400px {
    min-width: 165px !important;
  }

  .margin_in_mobile {
    padding: 0 34px;
  }

  .padding_in_mobile {
    padding: 0 34px;
  }

  .mobile_screen {
    width: 100%;
    max-width: 100%;
    /* padding: 0 34px; */
  }

  .text_hidden {
    width: 86%;
  }
}

@media only screen and (max-width: 280px) {
  .responsive_mobile_earn {
    width: 100%;
  }

  .border_earn_credit {
    padding: 30px 18px;
  }

  .fontInMobile {
    font-size: 10px;
  }

  .padding_in_mobile {
    padding: 0;
  }
}

@media only screen and (max-width: 768px) {
  .border_btn {
    width: 200px;
  }
  /* carousal responsive */
  .owl-carousel .owl-stage-outer {
    margin-left: 42px;
  }

  .m_top_2 {
    margin-top: 140px;
  }

  .search_option {
    width: 210px;
  }

  .owl-carousel.owl-theme .owl-nav .owl-next {
    width: 30px;
    height: 30px;
    font-size: 28px;
  }

  .owl-carousel.owl-theme .owl-nav .owl-prev {
    width: 30px;
    height: 30px;
    font-size: 28px;
  }

  .responsive_h5 {
    font-size: 14px;
  }

  .responsive_h3 {
    font-size: 18px;
  }

  .mainheading {
    font-size: 14px;
  }

  .owl-theme .owl-nav [class*="owl-"] {
    margin-right: 30px !important;
  }

  /* carousal responsive */
  .showInMobile {
    display: block;
  }

  .hiddenInMobile {
    display: none;
  }

  .first {
    order: 1;
  }

  .second {
    order: 2;
  }

  .third {
    order: 3;
  }

  .fourth {
    order: 4;
  }

  .display_none_descktop {
    display: block;
  }

  .display_none_mobile {
    display: none;
  }

  .paddbzero {
    padding-bottom: 20px;
  }

  .paddx40px {
    padding-right: 15px;
    padding-left: 15px;
  }

  .footer_btn {
    position: unset !important;
    text-align: center;
  }

  .login_logo {
    margin: 0 auto;
    margin-bottom: 12px;
  }

  .order1mobile {
    order: 1;
  }

  .order2mobile {
    order: 2;
  }

  .w25per_des,
  .past_order_box {
    width: 100%;
  }

  #body .second_tabs.order_tabs#myTab .nav-item .nav-link {
    min-width: 100%;
  }

  .w_50,
  .w_75,
  .w_85 {
    width: 100%;
  }

  .alignself_center {
    display: flex;
    margin: 50px auto 0 !important;
  }

  #body .second_tabs.order_tabs#myTab,
  .body .second_tabs#myTab {
    width: auto;
    /* border-radius: 25px; */
  }

  #body .second_tabs.order_tabs#myTab li,
  .body .second_tabs#myTab li {
    width: 100%;
  }

  #body .second_tabs.order_tabs#myTab .nav-item .active,
  #body .second_tabs.order_tabs#myTab .nav-item .nav-link,
  .body .second_tabs#myTab li a .active,
  .body .second_tabs#myTab li a {
    border-radius: 25px;
    min-width: 150px;
  }

  .btn-custom-onboard,
  .btn-custom-register {
    padding: 8px 20px;
  }

  /* .progress_line {
    width: 2px !important;
    height: 100%;
  } */

  .order_page .progress_line {
    width: 2px !important;
    height: 100%;
  }

  .w_360px {
    width: 80%;
  }

  .px_70px {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }

  .body .first_tabs#myTab {
    flex-direction: column;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .body .first_tabs#myTab .nav-item:last-child {
    border-bottom: 0 !important;
  }

  .box {
    margin-bottom: 20px;
  }

  .w_180px {
    width: 14% !important;
  }

  .h_130pxmobile {
    height: 130px !important;
    text-align: center;
  }

  .vertical_bottom_desk {
    display: block;
  }

  .w_20per {
    width: 100%;
    height: 40px;
  }

  .order_tracking_message {
    padding: 30px 20px;
  }

  .w_95per {
    width: 85%;
  }

  .display_none_descktop .progress_line {
    border: 2px dotted #e7e9ff;
  }

  .display_none_descktop .progress_line_active {
    border: none;
    background-color: #8ae3e8;
  }

  .w_35per {
    width: 100%;
  }

  .centered {
    position: unset;
    transform: none;
  }

  .w_25,
  .w_40 {
    width: 100%;
  }

  .position_style {
    top: 34%;
    right: 34px;
  }

  .vl {
    border-left: 0;
    height: 0;
  }

  .vl_2 {
    margin: 20px 0 0;
    border-top: 2px solid #3d4586;
    width: 180px;
  }
}

.w_35 {
  width: 35%;
}

.tabs_imgs_height {
  height: 40px;
  object-fit: contain;
}

@media only screen and (min-width: 992px) {
  .sr_font {
    font-size: 14px;
    padding: 0;
    margin: 0;
  }
}

@media only screen and (min-width: 1230px) {
  .sr_font {
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
}

@media only screen and (min-width: 2230px) {
  .sr_font {
    font-size: 16px;
  }
}

@media only screen and (max-width: 2330px) {
  .sr_font {
    font-size: 16px;
  }
}

.fw_semibold {
  font-family: "galano_grotesquesemibold";
}

.fw_medium {
  font-family: "galano_grotesque_altmedium";
}

.fw_regular {
  font-family: "galano_grotesqueregular";
}

.flexGrowOne {
  flex-grow: 1;
}

.flexShrinkZero {
  flex-shrink: 0;
}

.overflowYScroll {
  overflow-y: scroll;
}

.bg_f4f4f4 {
  background-color: #f4f4f4;
}

#promocode input::placeholder {
  text-transform: uppercase;
  color: #d1d1d1;
  font-size: 14px;
}

.min_height110px {
  min-height: 110px;
}

.w_40 {
  width: 40%;
}

.reviewInput {
  background: #f4f4f4;
  border: 2px solid #3d4586;
  border-radius: 6px;
  color: #3d4586 !important;
}

.reviewInput::placeholder {
  font-size: 12px;
  color: #c3c3c3 !important;
}

.w_103per {
  width: 103%;
}

.minHeight44px {
  min-height: 44px;
}

.color_green {
  color: #34a5ac;
}

@media only screen and (max-width: 991px) {
  .slider_page {
    display: none;
  }
}
@media (min-width: 991px) {
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
}

.themeColor {
  color: #3d4587;
}

.laundry-size {
  font-weight: bold;
}
.laundry_inner {
  border: 3px solid #3d4587;
  border-radius: 8px;
}
.below_index {
  z-index: -1;
}
.top-15 {
  top: 15px;
}
.bottom-15 {
  bottom: 15px;
}
.flex-1 {
  flex: 1;
}
.footer {
  background: #3d4587;
}
.rounded-8px {
  border-radius: 8px;
}
