.vh-90 {
	height: 90vh!important
}

.w-40 {
	width: 40%;
}

.box_404 {
	background: #ffffff5e;
}

.theme_bg_btn {
	background: #E7E9FF;
}